@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap");

:root {
    /* Fuente */

    --font-lato: "Lato", sans-serif;
    --fs-body: 16px;
    --fs-heading-pagina: 200px;
    --fs-heading-13xl: 160px;
    --fs-heading-10xl: 128px;
    --fs-heading-xxl: 50px;
    --fs-heading-xl: 41px;
    --fs-heading-lg: 28px;
    --fs-heading-md: 25px;
    --fs-heading-sm: 21px;

    /* Major Third Scale Migration */

    --fs-t1: 1.25rem;
    --fs-t2: 1.563rem;
    --fs-t3: 1.953rem;
    --fs-t4: 2.441rem;
    --fs-t5: 3.052rem;
    --fs-t6: 3.815rem;
    --fs-t7: 4.768rem;

    /* Colores */

    --color-primary: #375fa0;
    --color-primary-400: #0772ff;
    --color-primary-500: #0561aa;
    --color-primary-700: #193166;
    --color-primary-800: #22315f;
    --color-primary-900: #0b1f3b;
    --color-secondary: #e6c55b;
    --color-secondary-400: #f4c300;
    --color-secondary-300: #f7d24c;
    --color-font: #333333;
    --color-font-light: #545454;
    --color-button-primary: #2a3e67;
    --color-white: #fff;
    --color-light-gray: #f8f8f9;
    --line_color: #2a3e67;

    /* Untitled UI gray scale */
    --color-gray-25: #fcfcfd;
    --color-gray-50: #f9fafb;
    --color-gray-100: #f2f4f7;
    --color-gray-200: #eaecf0;
    --color-gray-300: #d0d5dd;
    --color-gray-400: #98a2b3;
    --color-gray-500: #667085;
    --color-gray-600: #475467;
    --color-gray-700: #364054;
    --color-gray-800: #1d2939;
    --color-gray-900: #101828;
    --color-gray-950: #0c111d;
}

@media screen and (max-width: 1600px) {
    :root {
        --fs-body: 16px;
        --fs-heading-pagina: 144px;
        --fs-heading-13xl: 128px;
        --fs-heading-10xl: 96px;
        --fs-heading-xxl: 50px;
        --fs-heading-xl: 41px;
        --fs-heading-lg: 28px;
        --fs-heading-md: 25px;
        --fs-heading-sm: 21px;

    }
}

@media screen and (max-width: 1200px) {
    :root {
        --fs-body: 14px;
        --fs-heading-pagina: 112px;
        --fs-heading-13xl: 128px;
        --fs-heading-10xl: 48px;
        --fs-heading-xxl: 32px;
        --fs-heading-xl: 32px;
        --fs-heading-lg: 28px;
        --fs-heading-md: 20px;
        --fs-heading-sm: 16px;
    }
}

@media screen and (max-width: 992px) {
    :root {
        --fs-body: 14px;
        --fs-heading-pagina: 96px;
        --fs-heading-13xl: 96px;
        --fs-heading-10xl: 48px;
        --fs-heading-xxl: 32px;
        --fs-heading-xl: 32px;
        --fs-heading-lg: 22px;
        --fs-heading-md: 20px;
        --fs-heading-sm: 16px;
    }
}

@media screen and (max-width: 768px) {
    :root {
        --fs-body: 14px;
        --fs-heading-pagina: 64px;
        --fs-heading-13xl: 50px;
        --fs-heading-10xl: 48px;
        --fs-heading-xxl: 32px;
        --fs-heading-xl: 28px;
        --fs-heading-lg: 22px;
        --fs-heading-md: 20px;
        --fs-heading-sm: 16px;

        /* Minor Third Scale Migration */

        --fs-t1: 1.2rem;
        --fs-t2: 1.44rem;
        --fs-t3: 1.728rem;
        --fs-t4: 2.074rem;
        --fs-t5: 2.488rem;
        --fs-t6: 2.986rem;
        --fs-t7: 3.583rem;

    }


}
