.buscador__container {
  .buscador__input {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    color: var(--color-font);
    font-size: 14px;
    padding: 12px;
    width: 70%;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
      opacity: 1;
    }
  }
  .buscador__button {
    background-color: var(--color-primary-800);
    border: none;
    color: var(--color-white);
    position: relative;
    padding: 0.5rem 1rem;
    border-radius: 0 3px 3px 0;
  }
}

.resultado__container {
  margin-bottom: 3rem;

  .resultado__nombre {
    font-size: 26px;
    font-weight: 700;
    color: var(--color-primary-700);
    cursor: pointer;
  }

  .resultado__fecha {
    font-size: 15px;
    font-weight: 500;
    color: var(--color-font);
  }

  .resultado__descripcion {
    font-size: 15px;
    font-weight: 500px;
    color: var(--color-font);
    margin-top: 1.5rem;
  }
}
