.footer__container {
  /* Add sahdown like a box shadow */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  /* Add border radius */
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  width: 95%;
  margin: 2rem auto;

  padding: 2% 4% 1% 4%;
}


//On mobile add margin bottom
@media screen and (max-width: 992px) {
  .footer__container {
    margin-bottom: 5rem;
  }
}

  .footer__copy{
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 1rem;


    .footer__copy-text{
      color: var(--color-font-light);
      font-weight: 700;
    }
  }


