.hero {
    position: relative;
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;

    .hero__video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .hero__content {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: white;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .backdrop__video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: -1;
        }

        .hero__carousel {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            .hero__text__container {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                width: 100%;
                height: 100vh;

                .hero__text {
                    font-size: var(--fs-t6);
                    font-weight: 700;
                    text-align: left;
                    line-height: 1.3;
                    max-width: 60%;
                }

                .hero__text--small {
                    font-size: var(--fs-t3);
                    font-weight: 700;
                    text-align: left;
                    line-height: 1.5;
                }

                .hero__text__description {
                    font-size: var(--fs-t2);
                    font-weight: 300;
                    text-align: left;
                    line-height: 1.5;
                    max-width: 60%;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .hero {
        .hero__content {
            align-items: center;
            .hero__carousel {
                .hero__text__container {
                    align-items: center;
                    .hero__text {
                        max-width: 100%;
                        text-align: center;
                    }

                    .hero__text--small {
                        max-width: 100%;
                        text-align: center;
                    }

                    .hero__text__description {
                        max-width: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }
}
