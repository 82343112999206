.count__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding-right: 1rem;

  /* Border dottet in the right side of the container */
  background-image: linear-gradient(black 33%, rgba(255, 255, 255, 0) 0%);
  background-position: right;
  background-size: 1px 3px;
  background-repeat: repeat-y;

  .count__number__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;

    .count__number {
      font-size: 56px;
      font-weight: 700;
      color: var(--color-secondary-400);
    }

    .count__icon {
      font-size: 32px;
      color: var(--color-secondary-400);
      margin-right: 1rem;
    }
  }

  .count__text {
    font-size: 18px;
    font-weight: 700;
    color: var(--color-gray-700);
  }
}
