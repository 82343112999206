.button {
    position: relative;
    z-index: 0;
    width: 300px;
    height: 56px;
    text-decoration: none;
    font-size: var(--fs-body);
    font-weight: bold;
    color: var(--line_color);
    transition: all 0.3s ease;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.button__text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    text-align: center;
    height: 100%;
}
.button::before,
.button::after,
.button__text::before,
.button__text::after {
    content: "";
    position: absolute;
    height: 3px;
    border-radius: 2px;
    background: var(--line_color);
    transition: all 0.5s ease;
}
.button::before {
    top: 0;
    left: 54px;
    width: calc(100% - 56px * 2 - 16px);
}
.button::after {
    top: 0;
    right: 54px;
    width: 8px;
}
.button__text::before {
    bottom: 0;
    right: 54px;
    width: calc(100% - 56px * 2 - 16px);
}
.button__text::after {
    bottom: 0;
    left: 54px;
    width: 8px;
}
.button__line {
    position: absolute;
    top: 0;
    width: 56px;
    height: 100%;
    overflow: hidden;
}
.button__line::before {
    content: "";
    position: absolute;
    top: 0;
    width: 150%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    border: solid 3px var(--line_color);
}

.button__line:nth-child(1),
.button__line:nth-child(1)::before {
    left: 0;
}
.button__line:nth-child(2),
.button__line:nth-child(2)::before {
    right: 0;
}
.button:hover {
    background-color: rgba(11, 31, 59, 0.2);
    color: var(--color-primary-700);
}
.button:hover::before,
.button:hover .button__text::before {
    width: 8px;
}
.button:hover::after,
.button:hover .button__text::after {
    width: calc(100% - 56px * 2 - 16px);
}

.button--lg {
    scale: 1.4;
}
