/* ----------------------------------------------
 * Generated by Animista on 2023-7-5 14:24:25
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-right-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-right-fwd {
    0% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
        -webkit-transform-origin: right;
        transform-origin: right;
    }
    100% {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
        -webkit-transform-origin: right;
        transform-origin: right;
    }
}
@keyframes swing-right-fwd {
    0% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
        -webkit-transform-origin: right;
        transform-origin: right;
    }
    100% {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
        -webkit-transform-origin: right;
        transform-origin: right;
    }
}

.detalle__titulo {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 1rem;
    color: var(--color-button-primary);
}

.servicio__descripcion {
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 1rem;
    line-height: 31px;
    color: var(--color-font-light);
}

.detalle__descripcion {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 1rem;
    line-height: 32px;
    color: var(--color-font-light);
}

.detalle__icon {
    font-size: 20px;
    margin-right: 1rem;
    font-family: "etmodules";
    font-weight: 900;
    font-size: 70px;
    color: var(--color-secondary);
    margin-bottom: 32px;
}

.icon__edit::before {
    content: "\6c";
}

.icon__search::before {
    content: "\55";
}

.icon__pencil::before {
    content: "\6a";
}

.servicio__container {
    z-index: 2;
    .servicio__info {
        color: white;
        font-weight: 700;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .servicio__categoria {
            background-color: rgba(225, 197, 108, 0.15);
            border-radius: 6px;
            padding: 4px 16px;
            color: var(--color-secondary);
        }

        .servicio__titulo {
            font-size: 48px;
            font-weight: 700;
            margin-bottom: 1rem;
            color: white;
            text-align: start;
        }

        .servicio__descripcion {
            font-size: 17px;
            font-weight: 300;
            margin-bottom: 1rem;
            line-height: 31px;
            color: white;
            text-align: start;
            max-width: 60%;
        }
    }

    .servicio__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@media (max-width: 768px) {
    .servicio__container {
        .servicio__info {
            align-items: center;
            .servicio__titulo {
                font-size: 32px;
                text-align: center;
            }

            .servicio__descripcion {
                max-width: 100%;
                text-align: center;
            }
        }
    }
}
