.info__element {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;

  p{
    img{
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
  }

  .info__element__icon {
    color: var(--color-primary-900);
    font-size: 32px;
    margin-right: 1.5rem;
    display: flex;
    justify-content: center;
    svg {
      width: 32px;
      height: 32px;
    }
  }

  &:hover {
    .info__icon__label::before {
      color: var(--color-secondary);
      transition: all 0.3s ease-in-out;
    }
  }

  .info__element__text--white {
    p {
      color: var(--color-white);
    }
  }

  p {
    color: var(--color-font);
    font-weight: 300;
    font-size: var(--fs-body);

    a {
      color: var(--color-gray-700);
      text-decoration: none;
    }

    b {
      color: var(--color-gray-700);
      font-weight: 700;
    }
  }
}
