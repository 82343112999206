.post__article {
    .post__header{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .post__title{
            font-size: var(--fs-t4);
            font-weight: 700;
            color: var(--color-primary-800);
            margin-bottom: 0.5rem;
            transition: all 0.3s ease;
        }

        .post__author, .post__date, .post__category{
            display: flex;
            align-items: center;
            padding: 0.5rem 0;
            justify-content: center;
            margin-right: 1rem;
        }

        .post__date, .post__category{
            span {
                font-weight: 400;
                color: var(--color-gray-600);
            }

            svg {
                width: 24px;
                height: 24px;
                margin-right: 0.5rem;
                color: var(--color-secondary);
            }
        }

        .post__info{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 1rem;

            .post__author{
                .post__author__avatar {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    object-fit: cover;
                    margin-right: 0.5rem;
                    flex-shrink: 0;
                }
                .post__author__name {
                    font-size: var(--fs-body);
                    font-weight: 400;
                    color: var(--color-gray-600);
                    padding-right: 1rem;
                }
            }
        }



    }
    .post__cover__image {
        width: 100%;
        height: 40vh;
        border-radius: 10px;
        max-height: 400px;
        object-fit: cover;

    }
}
