.container__parallax__img {
  background-image: url('../../assets/img/Carrusel1.jpg');
}

.carousel__container {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 5rem 0;
  color: #fff;
  position: relative;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .opinions__title {
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    padding: 3rem 2rem 0 2rem;
  }
  .opinion__container {
    display: flex;
    padding: 6rem 12rem;
    flex-direction: row;
    width: 95%;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    height: 300px;

    .opinion__text {
      .opinion__person {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 1rem;
      }
      .opinion__description {
        font-size: 16px;
        font-style: italic;
        font-weight: 300;
      }
    }
    .opinion__img {
      border-radius: 10px;
      height: 100%;
      width: auto;
      margin-right: 5rem;
    }
  }
}

.carousel__container__parallax__hidden {
  height: 50vh;
}

@media screen and (max-width: 992px) {
  .carousel__container {
    .opinion__container {
      flex-direction: column;
      padding: 3rem;

      .opinion__text {
        text-align: center;
        margin-bottom: 2rem;
      }
      .opinion__img {
        margin-right: 0;
        margin-bottom: 2rem;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .carousel__container {
    .opinion__container {
      height: 350px;
    }
  }
}

@media screen and (max-width: 576px) {
  .carousel__container {
    .opinion__container {
      height: 100%;
    }
  }
}
