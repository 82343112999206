/* ==========================================================================
    * Navegación
   ========================================================================== */

/**
 * Contenedor de la barra de navegación
 * Elimina el espaciado para que el contenido se ajuste a la altura de la barra de navegación
 * Agrega transición para el cambio de color de fondo en scroll
 */

.nav-body {
    padding: 0px !important;
    transition: background-color 0.3s ease-in-out;
}

/* Contenedor del logo de COSINTE */

.navbar-brand {
    height: auto !important;
    padding: 0px !important;
    margin: 0 !important;
}

/* Logo de COSINTE */

.brand__navbar {
    width: 250px;
    height: auto;
}

/* Contenedor de enlaces y iconos de redes sociales */

.navbar-nav {
    padding: 0 !important;
}

/* Enlaces de la barra de navegación */

.nav-link {
    font-size: 15px !important;
    transition: all 0.3s ease-in-out !important;
    padding: 32px 16px !important;
    color: var(--color-white) !important;

    &:after {
        transition: all 0.3s ease-in-out !important;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 0%;
        content: ".";
        color: transparent;
        background: var(--color-secondary);
        height: 1px;
    }

    &:hover:after {
        width: 100%;
    }

    &.active {
        color: var(--color-secondary) !important;
        background-color: rgba(0, 0, 0, 0.1);

        &:after {
            width: 100%;
        }

        &:hover {
            color: var(--color-secondary) !important;
        }
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
        color: var(--color-secondary) !important;
    }
}

/**
 * Contendor de los íconos, lo que incluye
 * - Ícono de instagram
 * - Ícono de facebook
 * - Ícono de CIA
 * - Ícono de correo
 */

.logos__container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
}

/**
 * Ícono de CIA
 * Muestra un rectángulo con el color amarillo de fondo
 * Agrega efecto hover para que el ícono se haga más grande
 */

.logo__cia {
    width: 0px;
    height: 86px;
    border-left: 30px solid var(--color-secondary);
    border-right: 30px solid var(--color-secondary);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    top: 0px;
    transition: all 0.3s ease-in-out;
    border-top: 86px solid var(--color-secondary);

    img {
        width: 40px;
        height: auto;
        position: relative;
        top: -50px;
        left: 0;
        transition: all 0.3s ease-in-out;
    }

    &:hover {
        img {
            scale: 1.2;
        }
    }
}

/* Contendor del dropdown de servicios */

.dropdown__servicios {
    position: absolute;
    top: 80px;
    left: 50%;
    display: none;
    width: 80%;
    padding: 1.5rem;
    border-top: 3px solid var(--color-secondary);
    background-color: #fff;
    border-radius: 10px;
    transform: translate(-50%, 0);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    .dropdown__list__title {
        display: block;
        padding: 8px 32px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.03);
        color: var(--color-button-primary);
        font-size: 16px;
        font-weight: 700;

        .nav-link {
            font-weight: 400 !important;
        }
    }

    .dropdown__list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        height: auto;
        padding: 0;
        padding-bottom: 16px;
        margin: 0;

        .dropdown__item {
            width: 100%;
            padding: 8px 20px;
            text-align: left;
            list-style: none;
            cursor: pointer;

            &:hover {
                background-color: rgba(230, 197, 91, 0.15);

                .dropdown__link {
                    color: var(--color-primary-500);
                }
            }
        }

        .dropdown__link {
            width: 100%;
            color: var(--color-gray-600);
            text-decoration: none;

            &:hover {
                color: var(--color-primary-900);
            }
        }
    }
}

/* Enlace que despliega el dropdown */

.dropdown__title {
    &:hover {
        .dropdown__servicios {
            display: block;
        }
    }
}

/* Reestablecer las propiedades del ícono de menú de hamburguesa de bootstrap */

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
}

/* Cambiar el ícono de menú de hamburguesa de bootstrap */

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(230, 197, 91)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

/* Agregar scroll a la barra de navegación si es necesario */

.navbar-nav-scroll {
    overflow-y: visible !important;
}

@media screen and (max-width:1200px) {

  .nav-link{
    font-size: 14px !important;
    text-align: center;
    padding: 32px 8px !important;
  }
  .brand__navbar {
    width: 180px;
  }
}


/* Utilidades
   ========================================================================== */

/* Utilidad para establecer el color de fondo transparente */

.transparent {
    background-color: rgba(11, 31, 59, 0.5);
}

/* Utilidad para establecer el color de fondo sólido */

.solid {
    background-color: var(--color-primary-900);
}

/**
 * Utilidad para establecer la posición como relativa
 * Se utiliza para que el efecto hover este localizado en el elemento
 */

.relative-link {
    position: relative;
}

/* Utilidad para evitar el efecto hover en el elemento */

.no-hover {
    &::after {
        display: none !important;
    }
}

/* Media queries
   ========================================================================== */

@media screen and (max-width: 992px) {
    .transparent {
        background-color: var(--color-primary-900);
    }

    .nav-body {
        padding: 24px 0 !important;
    }

    .navbar-nav {
        margin-top: 16px !important;
    }

    .navbar-nav-scroll {
        overflow-y: auto !important;
        max-height: 100vh !important;
    }

    .nav-link {
        padding: 4px 16px !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
    }

    .logos__container {
        margin: 3rem 0;
    }

    .logo__cia {
        height: 48px;
        border-top: 48px solid var(--color-secondary);
        img {
            top: -32px;
        }
    }

    .dropdown__servicios {
        display: block;
        position: relative;
        top: 0;
        padding: 0;
        border: none;
        width: 100%;
        border-radius: 0;
        padding-left: 5%;
        box-shadow: none;
        margin-top: 16px;
        background-color: var(--color-primary-900);
        padding: 0;

        .dropdown__list {
            margin-left: 16px;
            padding: 0;
            background-color: var(--color-primary-900);

            .dropdown__item {
                padding: 4px 16px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
                color: rgba(255, 255, 255, 0.7);
                font-weight: 300;
                text-align: center;

                &:hover {
                    background-color: var(--color-primary-900);

                    .dropdown__link {
                        color: white;
                    }
                }
            }
        }

        .dropdown__list__title {
            font-size: 14px;
            padding: 4px 0;
            color: var(--color-white);
        }
    }
}

@media screen and (max-width: 1200px) {
    .nav-link {
        font-size: 14px !important;
        text-align: center;
        padding: 32px 8px !important;
    }

    .brand__navbar {
        width: 180px;
    }
}




