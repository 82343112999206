.lista__categorias {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;

    .categoria__item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 0rem;
        border-bottom: 1px solid var(--color-gray-200);
        margin: 0.5rem 0;
        font-size: 14px;
        text-decoration: none;
        width: 100%;
        transition: all 0.3s ease;
        span {
            &:first-child {
                color: var(--color-gray-600);
                font-size: var(--fs-body);
            }

            &:last-child {
                color: var(--color-gray-500);
                letter-spacing: 2px;
            }
        }

        &:hover {
            span {
                color: var(--color-primary-700);
            }

            border-bottom: 1px solid var(--color-gray-300);
        }

        //Last child dont have border bottom
        &:last-child {
            border-bottom: none;
        }
    }

    .categoria__item--active {
        span {
            &:first-child {
                color: var(--color-primary-700);
            }

            &:last-child {
                color: var(--color-secondary);
            }
        }
    }
}

.box__layout {
    border-radius: 8px;
    background-color: #fff;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;

    .layout__box__title {
        font-size: var(--fs-t2);
        font-weight: 700;
        color: var(--color-gray-800);
        text-align: left;
        padding: 1rem 2rem 0.7rem 2rem;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            left: 2rem;
            bottom: 0;
            width: 30px;
            height: 4px;
            border-radius: 4px;
            background: var(--color-secondary);
        }
    }
}

.articulos__relacionados {
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    .articulo__item {
        list-style: none;
        .articulo__link {
            text-decoration: none;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            padding: 0.5rem 0;
            transition: all 0.3s ease;
            padding-left: 1rem;

            .articulo__titulo {
                font-size: var(--fs-body);
                font-weight: 700;
                color: var(--color-gray-700);
            }
            .articulo__fecha {
                font-size: 14px;
                color: var(--color-gray-500);
            }

            &:hover {
                background-color: var(--color-gray-50);
                border-radius: 8px;

                .articulo__titulo {
                    color: var(--color-primary-800);
                }

                .articulo__fecha {
                    color: var(--color-primary-800);
                }
            }
        }
    }
}
