.portafolio__container {
  background-color: #f0f3f6;
  --bs-gutter-x: 0 !important;
  .portafolio__inner__container {
    margin: 0 auto;
  }
}

.containers {
  width: 600vw;
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;
}

.panel {
  width: 100vw;
  height: 100vh;
  will-change: transform;
}

.swiper-pagination-bullet{
  background-color: #fff;
  opacity: 0.5;
  width: 8px;
  height: 8x;
}

.swiper-pagination-bullet-active {
  background-color: #fff;
  opacity: 1;
}

:root {
  --swiper-theme-color: #fff;
  --swiper-navigation-size: 24px;
  --swiper-navigation-top-offset: 50%;
  --swiper-navigation-sides-offset: 16px;
  --swiper-navigation-color: var(--swiper-theme-color);
}

@media screen and (max-width: 768px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
  
}
