.card {
    position: relative;
    padding: 32px 16px 16px 16px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 15px !important;
    cursor: pointer;
    transition: box-shadow 0.25s;

    &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 15px;
        background-color: var(--color-primary-900);
    }

    .icon {
        position: relative;
        z-index: 2;
        display: table;
        width: 32px;
        height: 32px;
        padding: 12px;

        &::after {
            content: "";
            position: absolute;
            border: 1px solid rgba(255, 255, 255, 0.3);
            background-color: rgba(255, 255, 255, 0.05);
            inset: 4.5px;
            border-radius: 50%;
            backdrop-filter: blur(2px);
            transition: background-color 0.25s, border-color 0.25s;
        }

        svg {
            position: relative;
            z-index: 1;
            display: block;
            width: 32px;
            height: 32px;
            transform: translateZ(0);
            color: var(--color-gray-400);
            transition: color 0.25s;
        }
    }

    h4 {
        position: relative;
        z-index: 2;
        margin: 12px 0 4px 0;
        color: white;
        font-family: inherit;
        font-size: 24px;
        font-weight: 600;
        line-height: 2;
    }

    p {
        position: relative;
        z-index: 2;
        margin: 0;
        color: var(--color-gray-400);
        font-size: 16px;
        font-weight: 400;
        line-height: 1.7;
    }

    .shine {
        border-radius: inherit;
        position: absolute;
        inset: 0;
        z-index: 1;
        overflow: hidden;
        opacity: 0;
        transition: opacity 0.5s;

        &:before {
            content: "";
            position: absolute;
            bottom: 55%;
            left: 50%;
            width: 150%;
            padding-bottom: 150%;
            background-image: conic-gradient(
                from 205deg at 50% 50%,
                rgba(255, 255, 255, 0) 0deg,
                var(--color-secondary) 25deg,
                rgba(255, 255, 255, 0.18) 295deg,
                rgba(255, 255, 255, 0) 360deg
            );
            border-radius: 50%;
            filter: blur(35px);
            opacity: 0.2;
            transform: translateX(-50%);
        }
    }

    .background {
        border-radius: inherit;
        position: absolute;
        inset: 0;
        overflow: hidden;
        -webkit-mask-image: radial-gradient(
            circle at 60% 5%,
            black 0%,
            black 15%,
            transparent 60%
        );
        mask-image: radial-gradient(
            circle at 60% 5%,
            black 0%,
            black 15%,
            transparent 60%
        );

        .tiles {
            opacity: 0;
            transition: opacity 0.25s;

            .tile {
                position: absolute;
                background-color: rgba(230, 197, 91, 0.1);
                animation-duration: 8s;
                animation-iteration-count: infinite;
                opacity: 0;

                &.tile-4,
                &.tile-6,
                &.tile-10 {
                    animation-delay: -2s;
                }

                &.tile-3,
                &.tile-5,
                &.tile-8 {
                    animation-delay: -4s;
                }

                &.tile-2,
                &.tile-9 {
                    animation-delay: -6s;
                }

                &.tile-1 {
                    top: 0;
                    left: 0;
                    width: 22.5%;
                    height: 10%;
                }

                &.tile-2 {
                    top: 0;
                    left: 22.5%;
                    width: 27.5%;
                    height: 10%;
                }

                &.tile-3 {
                    top: 0;
                    left: 50%;
                    width: 27.5%;
                    height: 10%;
                }

                &.tile-4 {
                    top: 0;
                    left: 77.5%;
                    width: 22.5%;
                    height: 10%;
                }

                &.tile-5 {
                    top: 10%;
                    left: 0;
                    width: 22.5%;
                    height: 22.5%;
                }

                &.tile-6 {
                    top: 10%;
                    left: 22.5%;
                    width: 27.5%;
                    height: 22.5%;
                }

                &.tile-7 {
                    top: 10%;
                    left: 50%;
                    width: 27.5%;
                    height: 22.5%;
                }

                &.tile-8 {
                    top: 10%;
                    left: 77.5%;
                    width: 22.5%;
                    height: 22.5%;
                }

                &.tile-9 {
                    top: 32.5%;
                    left: 50%;
                    width: 27.5%;
                    height: 22.5%;
                }

                &.tile-10 {
                    top: 32.5%;
                    left: 77.5%;
                    width: 22.5%;
                    height: 22.5%;
                }
            }
        }

        @keyframes tile {
            0%,
            12.5%,
            100% {
                opacity: 1;
            }

            25%,
            82.5% {
                opacity: 0;
            }
        }

        .line {
            position: absolute;
            inset: 0;
            opacity: 0;
            transition: opacity 0.35s;

            &:before,
            &:after {
                content: "";
                position: absolute;
                background-color: rgba(255, 255, 255, 0.1);
                transition: transform 0.35s;
            }

            &:before {
                right: 0;
                left: 0;
                height: 1px;
                transform-origin: 0 50%;
                transform: scaleX(0);
            }

            &:after {
                top: 0;
                bottom: 0;
                width: 1px;
                transform-origin: 50% 0;
                transform: scaleY(0);
            }

            &.line-1 {
                &:before {
                    top: 10%;
                }

                &:after {
                    left: 22.5%;
                }

                &:before,
                &:after {
                    transition-delay: 0.3s;
                }
            }

            &.line-2 {
                &:before {
                    top: 32.5%;
                }

                &:after {
                    left: 50%;
                }

                &:before,
                &:after {
                    transition-delay: 0.15s;
                }
            }

            &.line-3 {
                &:before {
                    top: 55%;
                }

                &:after {
                    right: 22.5%;
                }
            }
        }
    }

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 14px 28px,
            rgba(0, 0, 0, 0.17) 0px 10px 10px;

        .icon {
            &::after {
                background-color: rgba(230, 197, 91, 0.05);
                border-color: rgba(230, 197, 91, 0.3);
            }

            svg {
                color: var(--color-secondary);
            }
        }

        .shine {
            opacity: 1;
            transition-duration: 0.5s;
            transition-delay: 0s;
        }

        .background {
            .tiles {
                opacity: 1;
                transition-delay: 0.25s;

                .tile {
                    animation-name: tile;
                }
            }

            .line {
                opacity: 1;
                transition-duration: 0.15s;

                &:before {
                    transform: scaleX(1);
                }

                &:after {
                    transform: scaleY(1);
                }

                &.line-1 {
                    &:before,
                    &:after {
                        transition-delay: 0s;
                    }
                }

                &.line-2 {
                    &:before,
                    &:after {
                        transition-delay: 0.15s;
                    }
                }

                &.line-3 {
                    &:before,
                    &:after {
                        transition-delay: 0.3s;
                    }
                }
            }
        }
    }
}
