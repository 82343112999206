.indicador__link {
    color: var(--color-gray-600);
    font-size: 15px;
    font-weight: 300;
    text-decoration: none;
}

.indicador__link--active {
    color: var(--color-primary-900);
    pointer-events: none;
    font-weight: 700;
}

.indicador__separador::before {
    content: "\39";
    margin: 0 0.5rem;
    color: var(--color-gray-400);
    font-family: "etmodules";
    font-size: 13px;
    font-weight: 400;
}
