.btn-neon{
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    font-size: 24px;
    line-height: 50px;
    color: #f5f5f5;
    width: 180px;
    transition: 1s;
    transition-delay: .9s;
    position: relative;
    display: inline-block;
}

.btn-neon:hover{
    box-shadow: 0 0 10px #ff6f61,
                0 0 40px #ff6f61,
                0 0 80px #ff6f61;
    background-color: #ff6f61;
}

.btn-neon:hover polyline{
    stroke-dashoffset: -460;
}

.btn-neon svg{
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    width: 100%;
    height: 100%;
}

.btn-neon svg polyline{
    fill: transparent;
    stroke: #ff6f61;
    stroke-width: 2px;
    stroke-dasharray: 40 460;
    stroke-dashoffset: 40;
    transition:  .8s ease-in-out;
}