.links__container {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    justify-content: center;
    align-items: center;
    text-align: center;

    .links__title {
        font-weight: 700;
        margin-bottom: 2rem;
        color: var(--color-gray-700);
        font-size: var(--fs-heading-sm);
    }

    .links__list {
        padding: 0;
        .link__container {
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;

            .link__link {
                text-decoration: none;
                color: var(--color-primary-800);
                font-size: 18px;
                font-weight: 700;
                display: inline-block;
                position: relative;
                padding-bottom: 4px;

                &:after {
                    background: none repeat scroll 0 0 transparent;
                    bottom: 0;
                    content: "";
                    display: block;
                    height: 2px;
                    left: 50%;
                    position: absolute;
                    background: var(--color-secondary);
                    transition: width 0.3s ease 0s, left 0.3s ease 0s;
                    width: 0;
                }

                &:hover:after {
                    width: 100%;
                    left: 0;
                }
            }
        }
    }
}

.social__container {
    display: flex;
    flex-direction: row;

    a {
        text-decoration: none;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.social__icon {
    width: 40px;
    height: 40px;
    margin: 8px 16px;
    color: var(--color-primary-800);

    &:hover {
        color: var(--color-secondary);
        transition: all 0.3s ease-in-out;

        path {
            fill: var(--color-primary-800);
        }
    }
}

.lg {
    width: 40px;
    height: 40px;
    margin: 8px;
}

.social__icon--contraste {
    color: var(--color-primary-800);
    &:hover {
        path {
            fill: transparent;
            stroke: var(--color-primary-800);
        }
    }
 }


