.form {
  display: flex;
  flex-direction: column;
  width: 80%;

  .form__input {
    margin-bottom: 1rem;
    border: 1px solid var(--color-gray-500);
    border-radius: 5px;
    color: var(--color-font);
    font-size: 14px;
    font-weight: 300;
    padding: 16px;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: var(--color-gray-500);
      opacity: 1;
    }

    /* Active state */

    &:focus::placeholder {
      opacity: 0.5;
    }

    &:focus {
      border: 1px solid var(--color-primary-800);
      box-shadow: 0px 0px 0px 4px #F4F5F7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  }

  .form__input--textarea {
    min-height: 150px;
  }

  .form__button_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.legal {
  margin-top: 2rem;

  .legal__title {
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: var(--color-primary-800);
  }

  .legal__text {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 1rem;
    color: var(--color-font-light);
    line-height: 24px;
  }

  .legal__link {
    text-decoration: none;
    color: var(--color-primary-800);
    font-weight: 700;
    text-decoration: none;
  }
}


.form__button_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 768px) {
  .form {
    width: 100%;
  }
}
