.certificaciones__container {
  background-color: var(--color-primary-500);
  width: 80%;
  margin: 5rem auto;
  color: #fff;
  position: relative;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;



  background: rgba( 255, 255, 255, 0.05 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.2 );
  backdrop-filter: blur( 12.5px );
  -webkit-backdrop-filter: blur( 12.5px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  

  z-index: 2;


  .certificaciones__description__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .certificaciones__title {
      font-size: var(--fs-heading-md);
      font-weight: 700;
      text-align: center;
      color: var(--color-white);
      margin-bottom: 3rem;
    }

    .certificaciones__image {
      box-shadow: 0px 12px 12px -6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
    }

    .certificaciones__description {
      font-size: var(--fs-body);
      font-weight: 300;
      text-align: center;
      color: var(--color-white);
      margin-top: 4rem;
    }
  }
}

.backdrop{
  background-color: var(--color-primary-900);
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.6;
}

.slide__img{
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

//Cuando es tablet no se muestra la imagen slide__img

@media (max-width: 992px) {
  .certificaciones__container {
    max-width: 100%;
  }
  .slide__img{
    display: none;
  } 
}



