*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  font-family: var(--font-lato);
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  overflow-x: hidden;
  cursor: none;
}

body::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.cursor__outside {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 250;
  direction: ltr;
  contain: layout style size;
  pointer-events: none;
  transition: opacity 0.3s, color 0.4s;
  z-index: 9999;
}

.cursor {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  background-color: var(--color-secondary);
  transition-duration: 200ms;
  transition-timing-function: ease-out;
  animation: cursorAnim 2s infinite alternate;
  pointer-events: none;
}

.cursor::before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  border: 2px solid #BC9C20;
  border-radius: 50%;
  opacity: 0.5;
  animation: cursorAnim2 3s infinite alternate;
  pointer-events: none;
}

//Cuando cursor__outside tiene .cursor__outside.-text cancelar animacion del cursor dentro

.cursor__outside.-text .cursor{
  animation: unset !important;
  width: 0;
  height: 0;
}

.cursor__outside.-text .cursor::before {
  animation: unset !important;
  width: 0;
  height: 0;
}

@keyframes cursorAnim {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.5);
  }
}

@keyframes cursorAnim2 {
  from {
    transform: scale(1);
    opacity: 0.5;
  }
  to {
    transform: scale(3);
    opacity: 0;
  }
}

.clearfix__navbar {
  margin-top: 86px;
}

.toast__error {
  width: 100%;
  margin-top: 1rem;
  background-color: #fcf0f0;
  border-color: #d32d1f;
  border-width: 0.5px;
  .toast-header {
    color: #fff;
    background-color: #f44336;
  }
}

.toast__success {
  width: 100%;
  margin-top: 1rem;
  background-color: #effcf4;
  border-color: #4caf50;
  border-width: 0.5px;
  .toast-header {
    color: #fff;
    background-color: #4caf50;
  }
}

.grecaptcha-badge {
    bottom: 64px !important;
    z-index: 1000;
}

@font-face {
  font-family: "etmodules";
  src: local("etmodules"), url(../../fonts/etmodules.woff) format("woff");
}


//En pantallas de smarphones no mostrar el cursor
@media (max-width: 767px) {
  .cursor__outside {
    display: none;
  }
}
