.container__titulo {
  display: grid;
  grid-template-columns: 1fr auto;
  /* Columna flexible para el texto, columna auto para la imagen */
  align-items: center;
  /* Centra verticalmente */
  width: 100%;
  white-space: nowrap;

  .texto {
    color: #eee;
    font-size: var(--fs-heading-pagina);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.92px;
    justify-self: start;
    margin: 3rem 0 0rem 0;
    transform-origin: left;
  }

  .titulo__imagen {
    justify-self: end;
    position: absolute;
    top: 0px;
    right: 0px;
    //width: 200px;
    //Calcular la aultura de la imagen para que se ajuste al alto del contenedor, teniendo en cuenta que el ancho del contenedor es variable
    height: calc(100% / 1.5);
    opacity: 0.6;
    max-height: 100vh;
    z-index: -9999;
  }

  .min-100vh {
    min-height: 100vh;
  }
}

@media (min-width: 1600px) {
  .container__titulo {
    .titulo__imagen {
      height: 60%;
      //display: none;
    }
    .min-100vh {
      min-height: 0;
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .container__titulo {
    .min-100vh {
      min-height: 0;
    }
  }
}

@media (max-width: 1200px) {
  .container__titulo {
    .titulo__imagen {
      height: calc(100% / 2)
    }

    .min-100vh {
      min-height: calc(100% / 2)
    }
  }
}

@media (max-width: 992px) {
  .container__titulo {
    .titulo__imagen {
      display: none;
      top: 0;
    }
  }
}

@media (max-width: 768px) {
  .container__titulo {
    text-align: center;
    white-space: normal;
    .texto{
      transform-origin: center;
    }
  }
}
