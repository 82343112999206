.card__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 100%;
  margin-top: 2rem;

  .button__container{
    display: flex;
    justify-content:center;
    align-items: flex-end;
    width: 100%;
    height: max-content;
  }

  .title__card {
    color: var(--color-primary-800);
    text-align: center;
    font-weight: 700;
    height: 30px;
    font-size: var(--fs-heading-sm);
  }
  .img__card {
    width: 366px;
    height: 215px;
    border-radius: 8px;
  }

  .description__card {
    color: var(--color-gray-600);
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 1rem;
  }

  .img__container {
    position: relative;
    display: inline-block;
    margin:  .5rem 0  2rem 0;
    //cursor: pointer;

    &:hover .overlay {
      opacity: 1; /* Mostrar el overlay */
    }

    img {
        //fit inside the container
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    span {
      &::before {
        font-family: 'etmodules';
        font-size: 32px;
        font-weight: 400;
        color: #fff;
        content: '\5a';
      }
    }

  }
}
