.container__frase {
  background-color: var(--color-primary-900);
  padding: 3rem;
  .inner__container__frase {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

      .frase__text {
        font-size: var(--fs-heading-md);
        font-weight: 700;
        color: white;
        text-align: center;
        padding: 0 3rem;
        margin: 0 !important;
      }
  }
}

.container__frase--white {
    background-color: white;
    .inner__container__frase{
        .frase__text {
            color: var(--color-primary-800);
        }
    }
}

@media (max-width: 768px) {
  .container__frase {
    padding: 1.5rem;
    .inner__container__frase {
      flex-direction: column;
      .frase__text {
        padding: 0;
      }
    }
  }
}

