.new__comment {
    background-color: #f8faff;
    padding: 1rem;
    border: 1px solid var(--color-gray-200);
    border-radius: 0.5rem;
    width: 100%;

    .new__comment__title {
        font-size: var(--fs-t1);
        font-weight: 700;
        margin-bottom: 1rem;
        color: var(--color-primary-900);
    }

    .new__comment__description {
        font-size: var(--fs-body);
        font-weight: 400;
        color: var(--color-gray-700);
        margin-bottom: 1rem;
    }

    textarea,
    input {
        width: 100%;
        padding: 1rem;
        border: 1px solid var(--color-gray-200);
        border-radius: 0.5rem;
        font-size: var(--fs-body);
        font-weight: 400;
        color: var(--color-gray-700);
    }

    textarea:focus,
    input:focus {
        outline: none;
        border: 1px solid var(--color-gray-400);
    }

    .error {
        color: #dc3545;
        font-size: 12px;
        font-weight: 300;
    }
}

.comments {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .comments__title {
        font-size: var(--fs-t2);
        font-weight: 700;
        margin-bottom: 1rem;
        color: var(--color-primary-900);
    }

    .comments__container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .comment {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;

        .comment__inner {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 1rem 0;
            width: 100%;
            border-bottom: 1px solid var(--color-gray-200);

            .comment__info {
                width: 100%;
                flex: 1;

                .comment__text {
                    font-size: var(--fs-body);
                    font-weight: 400;
                    color: var(--color-gray-700);
                    margin-bottom: 1rem;
                }
            }

            .comment__avatar {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                margin-right: 1rem;
                background-color: #faf3de;
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--color-primary-700);
                font-weight: 700;
            }

            .comment__header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;

                .comment__author {
                    font-size: var(--fs-t1);
                    font-weight: 700;
                    color: var(--color-primary-900);
                }

                .comment__date {
                    font-size: var(--fs-body);
                    font-weight: 400;
                    color: var(--color-gray-500);
                }
            }

            .comment__actions {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;
                margin-top: 1rem;
                font-size: 14px;

                .comment__add {
                    width: 100%;
                    flex: 1;
                }

                .comment__show,
                .comment__cancel {
                    color: var(--color-primary-900);
                    cursor: pointer;
                    font-weight: 700;
                    text-transform: uppercase;
                    letter-spacing: 0.5px;
                    font-size: 12px;
                    color: var(--color-primary-700);
                }

                .comment__show {
                    padding: 1rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg {
                        margin-left: 4px;
                        color: var(--color-secondary);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .comments {
        .comments__container {
            .comment {
                .comment__inner {
                    flex-direction: column;

                    .comment__info {
                        .comment__text {
                            margin-bottom: 0;
                        }
                    }

                    .comment__header {
                        flex-direction: column;
                        align-items: flex-start;
                        margin-bottom: 1rem;

                        .comment__author {
                            margin-bottom: 0.5rem;
                        }
                    }

                    .comment__actions {
                        flex-direction: column;
                        align-items: flex-start;

                        .comment__add {
                            margin-bottom: 1rem;
                        }
                    }
                }
            }
        }
    }
}
