.card__container__horizontal {
    position: relative;
    display: inline-block;

    .img__container {
        .img__card {
            //Rellenar el contenedor padre con la imágen
            width: 100vw;
            height: 100vh;
            object-fit: cover;
            margin-bottom: 0;
        }
    }

    .content__container {
        //Posicionar contenido encima de la imágen
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        padding: 3rem 0;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;

        .inner__container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .title__card {
                color: rgba(255, 255, 255, 0.3);
                text-align: center;
                font-weight: 700;
                font-size: var(--fs-heading-13xl);
            }

            .subtitle__card {
                color: var(--color-white);
                font-size: 30px;
                font-weight: 700;
                margin-bottom: 1rem;
                text-align: center;
                text-align: left;
                margin-top: 2rem;
            }

            .description__card {
                color: var(--color-font-light);
                font-size: var(--fs-heading-lg);
                line-height: 1.5;
                font-weight: 300;
                margin-bottom: 3rem;
                color: var(--color-white);
            }

            .scroll__indicator {
                //Ubicar a la derecha
                justify-self: flex-end;
                align-self: flex-end;
                display: flex;
                flex-direction: row;
                align-items: center;
                .scroll__message {
                    color: var(--color-white);
                    margin-right: 2rem;
                    font-weight: 300;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .card__container__horizontal {
        .content__container {
            .inner__container {
                display: flex;
                align-items: center;
                justify-content: center;

                .description__card {
                    text-align: center;
                }

                .subtitle__card {
                    text-align: center;
                }

                .scroll__indicator{
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .card__container__horizontal {
        flex-direction: column;
        align-items: center;
        margin: 2rem auto;

        .img__card {
            margin-bottom: 2rem;
        }

        .content__container {
            margin-left: 0;
            align-items: center;
            .inner__container {
                .description__card {
                    max-width: 100%;
                    margin-top: 1rem;
                }
            }
        }
    }
}


