.loader__container {
  display: flex;
  align-items: center;
  justify-content: center;

  .loader__texto {
    font-family: var(--font-lato);
    font-size: var(--fs-heading-md);
    color: var(--color-white);
  }
}

.loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  border-top: 2px solid #fff;
  border-right: 2px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin-right: 8px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
