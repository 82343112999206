.listapost__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    margin: 0 auto;
    max-width: 1200px;
    @media screen and (max-width: 992px) {
        grid-template-columns: repeat(1, 1fr);
    }

    .post__container {
        background-color: #fff;
        border-radius: 0.5rem;
        border: 1px solid var(--color-gray-300);
        overflow: hidden;
        transition: all 0.3s ease;
        text-decoration: none;

        .post__img__container {
            position: relative;
            overflow: hidden;
            height: 200px;

            .post__img {
                width: 100%;
                height: 200px;
                object-fit: cover;
                transition: all 0.3s ease;
                position: absolute;
            }

            .post__categories__container {
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                position: absolute;
                padding: .5rem;
                //put in bottom of the image
                bottom: 0;
                width: 100%;
                gap: 8px;
                //height: 100%;
                flex-wrap: wrap;

                .post__category {
                    background-color: var(--color-primary-900);
                    color: var(--color-white);
                    padding: 0.5rem 1rem;
                    font-size: 12px;
                    font-weight: 700;
                    text-transform: uppercase;
                    border-radius: 8px;

                    &:last-child {
                        margin-right: 0;
                    }

                    &:nth-child(even) {
                        background-color: var(--color-primary-800);
                    }

                    &:nth-child(odd) {
                        background-color: var(--color-primary-900);
                    }
                }
            }
        }
        .post__info {
            padding: 1rem;
            .post__titulo {
                font-size: var(--fs-t2);
                margin-bottom: 0.5rem;
                color: var(--color-gray-800);
                font-weight: 700;
                transition: all 0.3s ease;
            }
            .post__description {
                font-size: var(--fs-body);
                font-weight: 400;
                color: var(--color-gray-600);
            }
            .post__about {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 1rem;

                .post__date {
                    font-size: 14px;
                    font-weight: 400;
                    color: var(--color-gray-600);
                }

                .post__author {
                    display: flex;
                    align-items: center;
                    padding: 0.5rem 0;

                    .post__author__avatar {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        object-fit: cover;
                        margin-right: 0.5rem;
                        flex-shrink: 0;
                    }
                    .post__author__name {
                        font-size: var(--fs-body);
                        font-weight: 400;
                        color: var(--color-gray-600);
                        padding-right: 1rem;
                        font-size: 14px;
                    }
                }
            }
        }

        &:hover {
            //Add zoom effect on hover to the image
            .post__img {
                transform: scale(1.1);
            }
        }
    }
}
