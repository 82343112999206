.mouse{
    margin: 50px auto;
  }.mouse-icon{
     width: 25px;
     height: 45px;
     border: 2px solid white;
     border-radius: 15px;
     cursor: pointer;
     position: relative;
     text-align: center;
  }.mouse-wheel{
    height: 16px;
    margin: 2px;
    display: block;
    width: 8px;
    background-color: white;
    border-radius: 15px 0 0 0;
    -webkit-animation: 1.6s ease infinite wheel-up-down;
   -moz-animation: 1.6s ease infinite wheel-up-down;
    animation: 1.6s ease infinite wheel-up-down;
  }
  @-webkit-keyframes wheel-up-down {
      0% {

          opacity: 0;
      }
      30% {
          opacity: 1;
      }
      100% {

          opacity: 0;
      }
  }@-moz-keyframes wheel-up-down {
      0% {

          opacity: 0;
      }
      30% {
          opacity: 1;
      }
      100% {

          opacity: 0;
      }
  }@keyframes wheel-up-down {
      0% {

          opacity: 0;
      }
      30% {
          opacity: 1;
      }
      100% {

          opacity: 0;
      }
  }